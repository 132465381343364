@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Roboto", sans-serif;
  color: #252525;
  font-weight: 400;
  background-color: #ffffff;
  border-bottom: 1.6rem solid #2555db;
  min-height: 100vh;
  padding-bottom: 6rem;
}

h3 {
  font-size: 25px;
  color: #2555db;
  font-style: italic;
}

.container {
  max-width: 80rem;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}

/* *************** */

.header {
  align-self: stretch;
}

.header h1 {
  /* Non-accessible color */
  color: #2555db;
  /* color: #af8602; */

  text-transform: uppercase;
  text-align: center;
  font-size: 5.2rem;
  font-weight: 300;
  letter-spacing: 3px;
  position: relative;
  width: 100%;
  display: block;
}

.header h1::before,
.header h1::after {
  display: block;
  content: "";
  height: 3px;
  width: 4rem;
  background-color: #2555db;
  position: absolute;
  top: calc(50% - 1px);
}

.header h1::before {
  left: 0;
}

.header h1::after {
  right: 0;
}

/* *************** */

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.menu h2 {
  display: inline-block;
  padding: 1rem 0;
  border-top: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 500;
}

.menu > p {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.6;
  width: 80%;
}

.corsos {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 0.8rem; /* vertical gap */
  column-gap: 0rem; /* horizontal gap */
}

.corso {
  display: flex;
  gap: 1rem;
  margin: 0 1rem;
  max-width: 55rem;
  width: 90%;
  flex-shrink: 1;
}

.corso img {
  width: 12rem;
  aspect-ratio: 1;
  align-self: start;
}

.corso div {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 1rem;
  background-color: #bac6f0;
  border-radius: 5px;
  width: 30rem;
}

.corso h3 {
  font-size: 1.5rem;
  font-weight: 400;
}

.corso p {
  font-size: 1.3rem;
  font-weight: 300;
  font-style: italic;
  margin-bottom: auto;
  width: 100%;
}

.corso span {
  display: block;
  font-size: 1.5rem;
}

.corso.sold-out {
  color: #888;
}

.corso.sold-out img {
  filter: grayscale();
  opacity: 0.8;
}

/* *************** */

.footer {
  font-size: 1.4rem;
}

.order {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
}

.btn {
  color: white;
  font-family: inherit;
  border: none;
  font-size: 1.4rem;
  font-weight: 500;
  background-color: #2555db;
  padding: 1.4rem 4rem;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 5px;
}

.btn:hover {
  background-color: #6a88db;
  color: black;
}

/*
SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 40 / 48 / 64 / 80 / 96 / 128

FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 /52 / 62 / 74 / 86 / 98
*/

.desktop {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  width: 100vw;
}

.desktop .gruppo-wrapper {
  background-color: #ffffff;
  height: 776px;
  overflow: hidden;
  /* width: 100vw; */
  width: 100%;
}

.desktop .gruppo {
  height: 1278px;
  left: -93px;
  position: relative;
  top: -164px;
}

.desktop .rectangle {
  background-color: #4e60ff;
  /* box-shadow: 0px 4px 4px #00000040; */
  height: 827px;
  position: absolute;
  top: 118px;
  transform: rotate(0.35deg);
  box-sizing: border-box;

  width: 100%;
  /* overflow: hidden; */
}

.desktop .overlap-group {
  height: 1278px;
  position: relative;
  /* width: 100vw; */
}

.desktop .text-wrapper {
  color: #ffffff;
  font-family: "Inter-BlackItalic", Helvetica;
  font-size: 48px;
  font-style: italic;
  font-weight: 900;
  left: 256px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 30%;
  width: 725px;
  z-index: 1;
}

.desktop .ellipse {
  background-color: #ffffff;
  border-radius: 511px/224.84px;
  height: 450px;
  left: 801px;
  position: absolute;
  top: 25px;
  transform: rotate(2.85deg);
  /* width: 1022px; */
  width: 100%;
}

.desktop .div {
  background-color: #8a96ff;
  border-radius: 462.65px/217.4px;
  height: 435px;
  left: 754px;
  position: absolute;
  top: 375px;
  transform: rotate(2.85deg);
  width: 925px;
}

.desktop .text-wrapper-2 {
  color: #ffffff;
  font-family: "Inter-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 492px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 250px;
  white-space: nowrap;
  width: 139px;
}

.desktop .text-wrapper-3 {
  color: #ffffff;
  font-family: "Inter-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 618px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 250px;
  white-space: nowrap;
  width: 139px;
}

.desktop .text-wrapper-4 {
  color: #ffffff;
  font-family: "Inter-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 256px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 250px;
  white-space: nowrap;
  width: 236px;
}

.desktop .ellipse-2 {
  background-color: #ffffff;
  border-radius: 831.98px/261.85px;
  height: 524px;
  left: 31px;
  position: absolute;
  top: 629px;
  transform: rotate(-8.92deg);
  width: 1664px;
}

.desktop .img {
  height: auto;
  left: 811px;
  object-fit: cover;
  position: absolute;
  top: 396px;
  max-width: 70rem;
  min-width: 30rem;
}

.desktop .image {
  height: auto;
  left: 900px;
  object-fit: cover;
  position: absolute;
  top: 235px;
  width: 50rem;
  max-width: 100%;
}

/* CSS for tablet devices */
@media screen and (max-width: 1346px) {
  .corsos {
    grid-template-columns: 1fr 1fr;
  }
  .desktop .img {
    width: 40rem;
  }
  .desktop .image {
    width: 40rem;
    left: 750px;
  }
  .desktop .ellipse {
    left: 700px;
    position: absolute;
    top: 25px;
    transform: rotate(2.85deg);
    /* width: 1022px; */
    width: 100%;
  }
}

@media screen and (max-width: 1062px) {
  .desktop .img {
    width: 40rem;
  }
  .desktop .image {
    width: 40rem;
    left: 600px;
  }
  .desktop .ellipse {
    left: 500px;
    width: 110%;
  }
}

/* CSS for mobile devices */
@media screen and (max-width: 860px) {
  .corsos {
    grid-template-columns: 1fr;
  }
  .corso img {
    width: 8rem;
  }
  .text-wrapper {
    font-size: 5vw;
  }
  .desktop .img {
    width: 40rem;
    left: 150px;
    top: 600px;
  }
  .desktop .image {
    width: 15rem;
    left: 220px;
    top: 520px;
  }
  .desktop .ellipse {
    left: 200px;
    width: 100%;
    top: 160px;
  }
  .desktop .text-wrapper {
    font-size: 3vw;
    top: 45%;
    left: 20px;
  }
  .desktop .gruppo {
    height: 1278px;
    left: 0px;
    position: relative;
    top: -500px;
  }
  .desktop .gruppo-wrapper {
    background-color: #ffffff;
    height: 400px;
    overflow: hidden;
    /* width: 100vw; */
    width: 100%;
  }
  .desktop .ellipse-2 {
    background-color: #ffffff;
    border-radius: 831.98px/261.85px;
    height: 524px;
    left: -30px;
    position: absolute;
    top: 580px;
    transform: rotate(-8.92deg);
    width: 1664px;
  }
}
